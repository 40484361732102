import { ChangeEvent, FocusEvent } from 'react';
import { GraphQLFormattedError } from 'graphql';
import { ServerError } from '@apollo/client/link/utils';
import { ServerParseError } from '@apollo/client/link/http';
import { format } from 'date-fns-tz';
import { intervalToDuration } from 'date-fns';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import qs from 'qs';
import uniq from 'lodash/uniq';

import { Locale, ParticipantAttributes } from '__generated__/graphql';
import config from '../config';

export type Update<T = any> = (value: T) => T;

export const sortedParticipantAttributes = [
  ParticipantAttributes.date_of_birth,
  ParticipantAttributes.gender,
  ParticipantAttributes.nationality,
  ParticipantAttributes.address,
  ParticipantAttributes.phone,
  ParticipantAttributes.emergency_phone,
];

/**
 * Strips http(s)://, www., trailing slash, and query parameters from a URL.
 */
const getUrlWithoutPrefix = (url: string) => (
  url.replace(/^https?:\/\//i, '').replace(/^www\./i, '').split('?')[0].replace(/\/$/, '')
);

const toggleInList = (list: any[], value?: any) => {
  if (typeof value === 'undefined') {
    return [];
  }

  const newList = [...list];

  const index = newList.indexOf(value);

  if (index > -1) {
    newList.splice(index, 1);
  } else {
    newList.push(value);
  }

  return newList;
};

// eslint-disable-next-line max-len
export const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

const validateEmail = (email: string) => emailRegex.test(email);
const validateIban = (iban: string) => /[a-z]{2}[0-9]{2}[a-z0-9]{11,}/i.test(iban);
const validateVatId = (value: string) => {
  // Patterns from https://github.com/ddeboer/vatin/blob/master/src/Validator.php
  const patterns: { [key: string]: RegExp } = {
    AT: /^U[A-Z\d]{8}$/,
    BE: /^[0|1]{1}\d{9}$/,
    BG: /^\d{9,10}$/,
    CH: /^E(-| ?)(\d{3}(\.)\d{3}(\.)\d{3}|\d{9})( ?)(MWST|TVA|IVA)$/,
    CY: /^\d{8}[A-Z]$/,
    CZ: /^\d{8,10}$/,
    DE: /^\d{9}$/,
    DK: /^(\d{2} ?){3}\d{2}$/,
    EE: /^\d{9}$/,
    EL: /^\d{9}$/,
    ES: /^[A-Z]\d{7}[A-Z]|\d{8}[A-Z]|[A-Z]\d{8}$/,
    FI: /^\d{8}$/,
    FR: /^([A-Z0-9]{2})\d{9}$/,
    GB: /^\d{9}|\d{12}|(GD|HA)\d{3}$/,
    HR: /^\d{11}$/,
    HU: /^\d{8}$/,
    IE: /^[A-Z\d]{8}|[A-Z\d]{9}$/,
    IT: /^\d{11}$/,
    LT: /^(\d{9}|\d{12})$/,
    LU: /^\d{8}$/,
    LV: /^\d{11}$/,
    MT: /^\d{8}$/,
    NL: /^\d{9}B\d{2}$/,
    NO: /^\d{9}(MVA){0,1}$/,
    PL: /^\d{10}$/,
    PT: /^\d{9}$/,
    RO: /^\d{2,10}$/,
    SE: /^\d{12}$/,
    SI: /^\d{8}$/,
    SK: /^\d{10}$/,
  };

  // Remove whitespace and uppercase the VAT ID.
  const vatId = value.toUpperCase().replace(/\s+/g, '');

  // Retrieve pattern based on country code.
  const country = vatId.substring(0, 2);
  const pattern = patterns[country];

  if (pattern) {
    return vatId.substring(2).match(pattern);
  }

  return false;
};

export const googleAnalyticsRegex = /(UA-[0-9]+-[0-9]+)|(G-[0-9A-Z]+)/i;
export const facebookPixelRegex = /[0-9]+/;
export const hexColorRegex = /^#[0-9A-F]{6}$/i;
export const zipCodeRegex = { NL: /^\d{4}\s*[A-Z]{2}$/i };

export const isValidLocale = (locale: any): locale is Locale => config.locales.includes(locale);

export interface InputHandler<V=string, E=HTMLInputElement | HTMLSelectElement> {
  type?: string;
  value: V | null;
  name: string;
  onChange: (event: ChangeEvent<E>) => void;
  setValue: (value: V | null) => void;
  onBlur?: (event: FocusEvent<E>) => void;
  touched?: boolean;
  touch?: () => void;
}

export interface ErrorAttributes {
  is_server_error?: boolean;
  [attribute: string]: any;
}

export interface Errors {
  [rule: string]: ErrorAttributes;
}

export interface ErrorBag {
  [attribute: string]: Errors;
}

interface ErrorResponse {
  graphQLErrors?: ReadonlyArray<GraphQLFormattedError>;
  networkError?: Error | ServerError | ServerParseError;
}

/**
 * Extracts validation errors from an ApolloError returned by the server.
 * Removes all 'input.' prefixes from error messages.
 *
 * @param prefix If a prefix is passed, then only errors with this prefix are extracted, with the
 *               prefix removed from the key.
 */
const getServerErrors = (error?: ErrorResponse | null, prefix?: string, isServerError: boolean = true) => {
  const errors = (error?.graphQLErrors?.[0]?.extensions?.validation || {}) as ErrorBag;

  return Object.keys(errors).reduce((accumulated, key) => {
    let newKey = key.replace(/^input\./, '');

    if (prefix) {
      if (!newKey.startsWith(`${prefix}.`)) {
        return accumulated;
      }

      newKey = newKey.replace(new RegExp(`^${prefix}\\.`), '');
    }

    accumulated[newKey] = mapValues(errors[key], (attributes) => ({
      ...attributes,
      is_server_error: isServerError,
    }));

    return accumulated;
  }, {} as ErrorBag);
};

const filterServerErrors = (errors: ErrorBag): ErrorBag => {
  const result = {} as ErrorBag;

  Object.keys(errors).forEach((key) => {
    const error = errors[key];
    const filteredError = {} as Errors;

    Object.keys(error).forEach((rule) => {
      if (!error[rule].is_server_error) {
        filteredError[rule] = error[rule];
      }
    });

    if (Object.keys(filteredError).length > 0) {
      result[key] = filteredError;
    }
  });

  return result;
};

const isGraphQLAuthenticationError = (error: ErrorResponse) => (
  error?.graphQLErrors?.[0]?.extensions?.category === 'authentication'
);

const isGraphQLValidationError = (error: ErrorResponse) => (
  error?.graphQLErrors?.[0]?.extensions?.category === 'validation'
);

const getStatusCode = (error: ErrorResponse) => {
  if (error.networkError && 'statusCode' in error.networkError) {
    return error.networkError.statusCode;
  }

  return null;
};

const concatPrefix = (start: string, end?: string): string => (
  [start, end].filter((value) => !!value).join('.') || ''
);

const addPrefix = <T extends {}>(object: T, prefix: string) => (
  Object.keys(object).reduce((result, key) => {
    const newKey = key.startsWith(`${prefix}.`) ? key : `${prefix}.${key}`;

    result[newKey as keyof T] = object[key as keyof T];

    return result;
  }, {} as T)
);

const rejectPrefix = <T extends {}>(object: T, prefix: string) => (
  Object.keys(object).reduce((result, key) => {
    if (!key.startsWith(`${prefix}.`)) {
      result[key as keyof T] = object[key as keyof T];
    }

    return result;
  }, {} as T)
);

const filterPrefix = <T extends {}>(object: T, prefix: string) => (
  Object.keys(object).reduce(
    (result, key) => {
      if (key === prefix || key.startsWith(`${prefix}.`)) {
        return {
          ...result,
          ...key.startsWith(prefix) && { [key.replace(new RegExp(`^${prefix}\\.?`), '')]: object[key as keyof T] },
        };
      }

      return result;
    },
    {} as T,
  )
);

const containsKeyPrefix = (object: object, prefix: string) => (
  Object.keys(filterPrefix(object, prefix)).length > 0
);

/**
 * Indicates whether a value is not an empty object/array, undefined, null or an empty string.
 */
const isEmpty = (value: any) => {
  if (isObject(value)) {
    // Objects and arrays
    return Object.values(value).length === 0;
  }

  return typeof value === 'undefined' || value === null || value === '';
};

/**
 * @param prefix Prefix of the form key without trailing dot
 */
const getFormKey = (prefix?: string, key?: string) => {
  if (prefix && key) {
    return `${prefix}.${key}`;
  }

  if (prefix) {
    return prefix;
  }

  return key;
};

const resizedImageUrl = (url: string, size: number) => {
  const parts = url.split('.');
  const extension = parts.pop();

  return [...parts, size, extension].join('.');
};

const inProductionEnvironment = () => window.location.href.startsWith('https://atleta.cc');

const inVitestEnvironment = () => import.meta.env.VITEST_WORKER_ID !== undefined;

const assetUrl = (path?: string) => {
  const assetUrl = window.atleta?.assetUrl || '';

  return `${assetUrl}/assets${path ? `/${path.replace(/^\//, '')}` : ''}`;
};

const decodeUrl = (state: string) => qs.parse(state) as any;

const encodeUrl = (params: any) => (
  qs.stringify(params, { encodeValuesOnly: true, skipNulls: true })
);

const getUrlParams = () => decodeUrl(window.location.search.substr(1));

const replaceUrlParams = (params: any) => `?${encodeUrl(params)}`;

const mergeUrlParams = (params: any) => replaceUrlParams({
  ...getUrlParams(),
  ...params,
});

const stripSensitiveUrlParams = (
  path: string, keys = [
    'participantId', 'participantToken', 'orderId', 'orderToken', 'email', 'participants', 'ssoReturnUrl', 'sourceUrl',
  ],
) => {
  const [pathName, query] = path.split('?');

  if (query) {
    const params = decodeUrl(query);

    keys.forEach((key) => {
      delete params[key];
    });

    return `${pathName}?${encodeUrl(params)}`;
  }

  return path;
};

const formatTime = (value: string | null) => {
  if (value === null) {
    return '';
  }

  const { hours, minutes, seconds } = secondsToTime(parseInt(value, 10));

  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const secondsToTime = (value: number) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor(value / 60) - hours * 60;
  const seconds = value - hours * 3600 - minutes * 60;

  return { hours, minutes, seconds };
};

const timeToSeconds = (hours: number = 0, minutes: number = 0, seconds: number = 0) => (
  hours * 3600 + minutes * 60 + seconds
);

const getAge = (dateOfBirth: Date, at?: Date) => {
  const interval = intervalToDuration({
    start: dateOfBirth,
    end: at || new Date(),
  });

  return interval.years ? interval.years : 0;
};

/**
 * @param {number} portion The fraction maximum of the element that may be visible in the viewport
 */
const isInViewport = (element: Element, portion: number = 0) => {
  const { top } = element.getBoundingClientRect();
  const height = Math.min(element.clientHeight, window.innerHeight);
  return (top - portion * height) >= 0 && (top + portion * height) <= window.innerHeight;
};

const getEmbedScript = (eventId: string, enableButton: boolean = true) => {
  const parts = [];

  if (enableButton) {
    parts.push(`<script type="text/javascript">
  window.Atleta = {
    options: {
      eventId: '${eventId}',
    },
  };
</script>`);
  }

  parts.push(`<script type="text/javascript">
  !function(){var e=document.getElementsByTagName("script")[0],t=document.createElement("script");t.src="https://cdn.atleta.cc/embed/widget.js";var n=document.querySelector("[nonce]");n&&t.setAttribute("nonce",n.nonce||n.getAttribute("nonce")),e.parentNode.insertBefore(t,e)}();
</script>`);

  return parts.join('\n\n');
};

/**
 * Return current Unix timestamp in seconds.
 */
const time = () => (
  Math.floor((new Date()).getTime() / 1000)
);

const getProjectIdFromId = (id: string) => id.substr(0, 4);

const formatDateStringToAriaLabel = (dateString: string): string => format(
  Date.parse(dateString),
  'EEEE, LLLL do, yyyy',
);

export const setWindowLocation = (url: string) => {
  window.location.href = url;
};

/**
 * Returns the locale in the URL or the one set by the server (via the window object).
 */
const guessLocale = (): Locale | null => {
  const candidates = [
    getUrlParams().locale, // URLs like ?locale=nl
    navigator.language.split('-')[0],
    window.atleta?.locale, // Configured by the server
  ];

  for (let i = 0; i < candidates.length; i++) {
    if (isValidLocale(candidates[i])) {
      return candidates[i];
    }
  }

  return null;
};

/** Capitalizes the first character of a string */
const ucFirst = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

/**
 * Converts an array like ['T-shirt', 'T-shirt', 'Medal'] to ['2× T-shirt', 'Medal'].
 */
const countItems = (items: string[]) => {
  const counts = items.reduce((counts, item) => ({
    ...counts,
    [item]: (counts[item] || 0) + 1,
  }), {} as { [title: string]: number; });

  return uniq(items).map((title) => `${counts[title] > 1 ? `${counts[title]}× ` : ''}${title}`);
};

export const parseNumber = (value: string) => (
  parseFloat(value
    // Strip everything that is not a digit, period, comma, or dash
    .replace(/[^\d.,-]/g, '')
    // Make sure the decimal separator is a dot
    .replace(/[,]/g, '.')
    // Remove all but the last dot
    .replace(/[.](?=.*[.])/g, ''))
);

const parseDistance = (title: string, decimals = 3) => {
  const match = title.match(/([0-9]+[.,]?[0-9]*?) *?(k|mi|m|hrs)/i);
  const distance = match?.[1];
  const unit = match?.[2].toLowerCase();

  if (distance && unit) {
    const parsedDistance = parseNumber(distance);

    const parsedUnit = {
      k: 'km',
      mi: 'mi',
      m: 'm',
      hrs: 'hrs',
    }[unit];

    if (parsedDistance && parsedUnit) {
      return {
        distance: Math.round(parsedDistance * 10 ** decimals) / 10 ** decimals, // Round to 3 decimals.
        unit: parsedUnit,
      };
    }
  }

  return {
    distance: null,
    unit: null,
  };
};

export {
  getUrlWithoutPrefix,
  toggleInList,
  validateEmail,
  validateIban,
  validateVatId,
  getServerErrors,
  isGraphQLAuthenticationError,
  isGraphQLValidationError,
  getStatusCode,
  concatPrefix,
  addPrefix,
  rejectPrefix,
  filterPrefix,
  filterServerErrors,
  containsKeyPrefix,
  isEmpty,
  getFormKey,
  resizedImageUrl,
  inProductionEnvironment,
  inVitestEnvironment,
  assetUrl,
  decodeUrl,
  encodeUrl,
  getUrlParams,
  replaceUrlParams,
  mergeUrlParams,
  stripSensitiveUrlParams,
  formatTime,
  secondsToTime,
  timeToSeconds,
  getAge,
  isInViewport,
  getEmbedScript,
  time,
  getProjectIdFromId,
  formatDateStringToAriaLabel,
  guessLocale,
  ucFirst,
  countItems,
  parseDistance,
};
