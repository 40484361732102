import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { createContext } from 'react';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

// @ts-ignore
// eslint-disable-next-line
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import { Locale } from '__generated__/graphql';
import { getSessionId } from '../common/Tracking';
import { inProductionEnvironment, isGraphQLAuthenticationError } from '../common/helpers';

interface ApolloContextValue {
  locale: Locale;
  setLocale: (locale: Locale) => void;
}

export const ApolloContext = createContext({} as ApolloContextValue);

export const cache = new InMemoryCache({
  typePolicies: {
    ParticipantFieldFilters: {
      keyFields: ['segment_id', 'participant_field_id'],
    },
    Event: {
      fields: {
        tickets_categories: {
          merge: false,
        },
        products_for_sale: {
          merge: false,
        },
        participant_attributes: {
          merge: false,
        },
        participant_fields: {
          merge: false,
        },
        notification_emails: {
          merge: false,
        },
      },
    },
    MailPermission: {
      fields: {
        events: {
          merge: false,
        },
      },
    },
    Message: {
      fields: {
        mail_permissions: {
          merge: false,
        },
      },
    },
    Participant: {
      fields: {
        available_mail_permissions: {
          merge: false,
        },
        granted_mail_permissions: {
          merge: false,
        },
      },
    },
    ParticipantFieldEntry: {
      fields: {
        choice_entries: {
          merge: false,
        },
      },
    },
    Project: {
      fields: {
        events: {
          merge: false,
        },
      },
    },
    Registration: {
      fields: {
        participants: {
          merge: false,
        },
      },
    },
  },
});

const config = {
  headers: {},
};

type Headers = typeof config.headers;

export const setHeaders = (callback: (headers: Headers) => Headers) => {
  config.headers = callback(config.headers);
};

const link = ApolloLink.from([
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...config.headers,
      'Atleta-Session-ID': getSessionId(),
    },
  })),
  onError((error) => {
    let userMessage = null;

    const { networkError } = error;

    // @TODO: Show a nice dialog to users on network errors.
    if (networkError) {
      if ('statusCode' in networkError) {
        const { statusCode } = networkError;

        if (statusCode === 413) {
          userMessage = 'The file you are trying to upload is too big.';
        } else if (statusCode === 503) {
          // userMessage = 'We are down for maintenance. Please try again later.';
        } else {
          // userMessage = 'Oops, something went wrong. Please try again later.';
        }
      }
    } else if (isGraphQLAuthenticationError(error)) {
      // userMessage = 'You are not authorized to do this.';
    }

    if (userMessage) {
      // eslint-disable-next-line no-alert
      alert(userMessage);
    }

    if (!inProductionEnvironment()) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }),
  new RetryLink({
    // Wait for 2, 4, 6, ..., 30 seconds between retries.
    delay: (count) => Math.min(count * 2000, 30000),
    attempts: {
      // Retry 5xx responses.
      retryIf: (error) => error.statusCode >= 500 && error.statusCode < 600,
      // 27 retries is about 10 minutes. After that, give up. Default is 5 retries.
      max: 27,
    },
  }),
  createUploadLink({
    uri: '/api/graphql',
  }),
]);

export const client = new ApolloClient({
  cache,
  link,
});

export default undefined;
