import { A, BreadCrumb, Link, LinkBlock, Nav, NavItem, NavLink, Tab, Tabs } from './Nav';
import { AButton, Button, ConfirmButton, LinkButton, NavButton, Pill, TabButton, ToggleButton } from './Button';
import { AlertContainer, Error, Info, Success, Warning } from './Alert';
import { AnimateHeight } from './Animations';
import { AutoGridContainer, Container, FlexContainer, GridContainer, Section } from './Container';
import {
  Big, Caret, Code, H1, H2, H3, H4, H5, H6, Label, Legend, Paragraph, Pre, SectionHeading, Small,
  Strong, Sub, Sup, Truncate,
} from './Text';
import { Blockquote, Div, HR, Span } from './Generic';
import { Card, CollapsibleNavCard, NavCard, StickyCard, StickyCardHeader } from './Card';
import { Icon, Icons } from './Icon';
import { Image } from './Image';
import { LI, OL, UL } from './List';
import { Modal } from './Modal';
import { PickerContainer, PickerDropdown, PickerItem } from './Picker';
import { TBody, TD, TH, THead, TR, Table } from './Table';
import { Toggle } from './Toggle';
import { Tooltip, TooltipContainer } from './Tooltip';
import Badge from './Badge';
import BrandColors from './BrandColors';
import Checkbox from './Checkbox';
import DateInput from './DateInput';
import DatePicker from './LazyDatePicker';
import DebouncedInput from './DebouncedInput';
import Delimit from './Delimit';
import Dropdown, { DropdownOptionsContainer, DropdownToggle } from './Dropdown';
import DropdownSelect from './DropdownSelect';
import Editor from './LazyEditor';
import ErrorMessages from './ErrorMessages';
import FadeIn from './FadeIn';
import Form from './Form';
import FormButtons from './FormButtons';
import FormGrid from './FormGrid';
import GoogleLocationInput from './GoogleLocationInput';
import HTML from './HTML';
import Hero from './Hero';
import Input, { InputFallback } from './Input';
import InputGroup, {
  ErrorMessagesContainer, InputDescription, InputLabel, InputWrapper, RequiredMark,
} from './InputGroup';
import ListLimiter from './ListLimiter';
import Loader, { PageLoader } from './Loader';
import LogoContainer from './LogoContainer';
import NL2BR from './NL2BR';
import Pager from './Pager';
import PhoneNumberInput from './PhoneNumberInput';
import PinCodeInput from './PinCodeInput';
import ProfilePic from './ProfilePic';
import QuantitySelect from './QuantitySelect';
import Radio, { RadioPill } from './Radio';
import RenderCounter from './RenderCounter';
import ScrollablePanel from './ScrollablePanel';
import Select from './Select';
import ServerErrors from './ServerErrors';
import Shake from './Shake';
import StatusIndicator from './StatusIndicator';
import SubForm from './SubForm';
import TextArea from './TextArea';
import TimePicker from './TimePicker';
import ToggleBar, { ToggleBarButton } from './ToggleBar';

const UI = {
  A,
  AButton,
  AlertContainer,
  AnimateHeight,
  AutoGridContainer,
  Badge,
  Big,
  Blockquote,
  BrandColors,
  BreadCrumb,
  Button,
  Card,
  Caret,
  Checkbox,
  Code,
  CollapsibleNavCard,
  ConfirmButton,
  Container,
  DateInput,
  DatePicker,
  DebouncedInput,
  Delimit,
  Div,
  Dropdown,
  DropdownOptionsContainer,
  DropdownSelect,
  DropdownToggle,
  Editor,
  Error,
  ErrorMessages,
  ErrorMessagesContainer,
  FadeIn,
  FlexContainer,
  Form,
  FormButtons,
  FormGrid,
  GoogleLocationInput,
  GridContainer,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Hero,
  HR,
  HTML,
  Icon,
  Icons,
  Image,
  Info,
  Input,
  InputDescription,
  InputFallback,
  InputGroup,
  InputLabel,
  InputWrapper,
  Label,
  Legend,
  LI,
  Link,
  LinkBlock,
  LinkButton,
  ListLimiter,
  Loader,
  LogoContainer,
  Modal,
  Nav,
  NavButton,
  NavCard,
  NavItem,
  NavLink,
  NL2BR,
  OL,
  PageLoader,
  Pager,
  Paragraph,
  PhoneNumberInput,
  PickerContainer,
  PickerDropdown,
  PickerItem,
  Pill,
  PinCodeInput,
  Pre,
  ProfilePic,
  QuantitySelect,
  Radio,
  RadioPill,
  RenderCounter,
  RequiredMark,
  ScrollablePanel,
  Section,
  SectionHeading,
  Select,
  ServerErrors,
  Shake,
  Small,
  Span,
  StatusIndicator,
  StickyCard,
  StickyCardHeader,
  Strong,
  Sub,
  SubForm,
  Success,
  Sup,
  Tab,
  TabButton,
  Table,
  Tabs,
  TBody,
  TD,
  TextArea,
  TH,
  THead,
  TimePicker,
  Toggle,
  ToggleBar,
  ToggleBarButton,
  ToggleButton,
  Tooltip,
  TooltipContainer,
  TR,
  Truncate,
  UL,
  Warning,
};

export default UI;
