import { useApolloClient } from '@apollo/client';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import i18next from 'i18next';

import { Locale } from '__generated__/graphql';
import { guessLocale } from './helpers';
import { setHeaders } from '../api/apollo';
import config, { LocaleConfig } from '../config';

interface LocaleContextValue extends LocaleConfig {
  locale: Locale;
  timezone: string;
}

export const LocaleContext = React.createContext({} as LocaleContextValue);

interface LocaleProviderProps {
  locale?: Locale;
  localeFallback?: Locale;
  localizeRequests?: boolean;
  timezone?: string;
  children: ReactNode;
}

const LocaleProvider = ({
  locale: localeValue, localeFallback = Locale.en, localizeRequests = false, timezone, children,
}: LocaleProviderProps) => {
  const locale = localeValue || guessLocale() || localeFallback;

  const value = useMemo(() => ({
    ...config.locale[locale],
    locale,
    timezone,
  }), [locale, timezone]);

  const client = useApolloClient();
  const localeRef = useRef(locale);

  useEffect(() => {
    setHeaders((headers) => ({
      ...headers,
      'Atleta-Locale': localizeRequests ? locale : null,
    }));

    if (locale !== localeRef.current) {
      if (localizeRequests) {
        // Empties the Apollo store and refetches all active queries
        client.resetStore();
      }

      localeRef.current = locale;
    }

    i18next.changeLanguage(locale);
  }, [locale, localizeRequests, client]);

  return (
    <LocaleContext.Provider value={value}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
