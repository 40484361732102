import { initReactI18next } from 'react-i18next';
import i18next, { InitOptions } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import { Locale } from '__generated__/graphql';
import { guessLocale, inProductionEnvironment, inVitestEnvironment } from './common/helpers';
import { notifier } from './common/Common/RollbarProvider';
import config from './config';

/**
 * By default, every app supports every locale.
 * This can be overridden per entrypoint.
 */
const supportedLocales = { current: config.locales };

/**
 * Configure a list of locales that are supported by this app.
 * The user will never be able to switch to another locale.
 */
export const setSupportedLocales = (locales: Locale[]) => {
  supportedLocales.current = locales;
};

export const isSupportedLocale = (locale: Locale) => supportedLocales.current.includes(locale);

interface SetupTranslationsOptions {
  locale?: string;
  /** Namespaces to preload. First namespace is used as default namespace. */
  namespaces?: string[];
  locales?: (keyof typeof Locale)[];
  /** Location of the translation files.  */
  options?: InitOptions;
}

const missingKeys: { [key: string]: boolean; } = {};

export const setupTranslations = async ({
  locale = guessLocale(),
  namespaces = ['common'],
  options = {},
}: SetupTranslationsOptions) => {
  let failedLoading = false;
  i18next.on('failedLoading', () => {
    failedLoading = true;
  });

  i18next
    .use(initReactI18next)
    .use(resourcesToBackend((language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)))
    .init({
      ns: namespaces,
      lng: locale,
      fallbackLng: config.fallbackLocale, // Prevents i18n from falling back to 'dev' while loading
      defaultNS: namespaces[0],
      interpolation: {
        escapeValue: false,
      },
      saveMissing: true,
      missingKeyHandler: (locales, namespace, key) => {
        if (failedLoading) {
          // When the translations failed to load, all keys are missing.
          // No need to log.
          return;
        }

        const message = `Missing translation "${namespace}:${key}" (${locales.join()})`;

        if (!missingKeys[message]) {
          if (inProductionEnvironment()) {
            notifier.error(message);
          } else if (inVitestEnvironment()) {
            throw new Error(message);
          }

          // eslint-disable-next-line no-console
          console.error(message);
        }

        missingKeys[message] = true;
      },
      ...options,
    });
};

export default undefined;
